import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation } from '../../generated/types';

export const CANCEL_MEMBERSHIP = gql`
  mutation CancelMembership {
    cancelMembership
  }
`;

export const useCancelMembership = (options?: MutationHookOptions<Pick<Mutation, 'cancelMembership'>>) =>
  useMutation<Pick<Mutation, 'cancelMembership'>>(CANCEL_MEMBERSHIP, {
    ...options,
  });
